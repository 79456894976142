// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import ApiLogo from "../../../components/images/logo.api"
import GoToInstall from "../../../components/widgets/goToInstall"
import { CodeBlock } from "../../../components/widgets/codeBlock"
import { clienteScripts } from "../../../scripts/clientes"
import { cfdiScripts } from "../../../scripts/cfdi"
import SectionNavigate from "../../../components/widgets/sectionNavigate"

type DataProps = {
    site: {
        buildTime: string
    }
}

const ApiCfdiMinimo: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isApi={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ApiDocs' className="_DOCS withSide">
            <div className="content">
                <h2>Buscar facturas</h2>
                <p>
                    Se pueden buscar las facturas de la siguiente formas, la respuesta tiene el mismo formato:
                </p>
                <ul>
                    <li>
                        <p>Una a la vez por UUID</p>
                    </li>
                    <li>
                        <p>Las últimas 50 facturas</p>
                    </li>
                    <li>
                        <p>Un rango de fecha de facturas</p>
                    </li>
                </ul>
                <h4>
                    Buscar una factura a la vez
                </h4>
                <pre>
                    <code>
                        <b>GET:</b> https://api.fiscalpop.com/api/v1/cfdi/find/<b><span className="error">:authToken</span>/<span className="error">:uuid</span></b>
                    </code>
                </pre>
                <h4>
                    Buscar ultimas 50 facturas
                </h4>
                <pre>
                    <code>
                        <b>GET:</b> https://api.fiscalpop.com/api/v1/cfdi/find/<b><span className="error">:authToken</span></b>
                    </code>
                </pre>
                <h4>
                    Buscar por rango de fechas
                </h4>
                <pre>
                    <code>
                        <b>GET:</b> https://api.fiscalpop.com/api/v1/cfdi/findBy/<b><span className="error">:authToken</span>/<span className="error">:start</span>/<span className="error">:end</span></b>
                    </code>
                </pre>
                <h3>
                    Campos de la Respuesta
                </h3>
                <ul className="request">
                    <li>
                        <p>
                            <b>uuid: </b> El ID de la factura, la clave unica para identificar un CFDI.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>created: </b> Cuando fue creada la factura, indistinto de la fecha de la factura
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>json:</b> Representación del XML en JSON, util cuando se quiere usar un procesador de PDFs propio u alguna deconstrucción del contenido de la factura sin tener que parsear el XML.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>xml:</b> El string del XML de la factura, el cual es el CFDI en si.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>authToken:</b> El token del cliente emisor, usado para relacionar facturas con su emisor.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>status:</b> <code>true | false</code> True significa que la factura está vigente, false cuando la factura está cancelada.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>sandbox:</b> <code>true | false</code> Si la factura fué generada por cliente de prueba o producción, false es de producción, true es una factura de prueba.
                        </p>
                    </li>
                </ul>
                <SectionNavigate next={`/api/referencia/cfdi-facturar-pago`} previous={`/api/referencia/cfdi-cancel`}/>
            </div>
            <div className="side">
                <CodeBlock title="CFDI Buscar" requests={cfdiScripts.buscar.requests} response={cfdiScripts.buscar.response} />
            </div>
        </div>
    </Layout>
)

export default ApiCfdiMinimo
